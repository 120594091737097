import React, {Component} from 'react';
import styled from "styled-components"
import dividerXs from "images/divider-300.svg"
import dividerSm from "images/divider-700.svg"
import dividerLg from "images/divider-1200.svg"

const PaginatorLineWrapper = styled.div`
    img {
      margin: 3rem auto;
    }
`

class PaginatorLine extends Component {
    render() {
        return (
            <PaginatorLineWrapper>
                <img src={dividerXs} alt="" className="d-block d-sm-none"/>
                <img src={dividerSm} alt="" className="d-none d-sm-block d-xl-none"/>
                <img src={dividerLg} alt="" className="d-none d-xl-block"/>
            </PaginatorLineWrapper>
        );
    }
}

export default PaginatorLine;