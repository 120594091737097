import React, {Component} from "react"
import styled from 'styled-components'
import {Col, Container, Row} from "reactstrap";
import {graphql, StaticQuery} from 'gatsby'
import PaginatorLine from 'components/shared/PaginatorLine'
import Layout from "components/Layout/Layout"
import Map from "components/HomePage/Map"
import HeadingSpecial from "components/shared/HeadingSpecial"
import VideoButton from "components/shared/VideoButton"
import VideoModal from "components/shared/VideoModal"
import {media} from "utils/Media"
import {GatsbyImage} from "gatsby-plugin-image";

const scrollToElement = require('scroll-to-element')

const AccessHeader = styled.div`
  position: relative;
`

const RelaxedPerformancesContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 4;
  text-align: center;

  .narrow {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }

  .btnWrapper {
    max-width: 800px;
    margin: 2rem auto;

    @media ${media.md} {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .btn {
      text-transform: none;
    }
  }

  .simple-link {
    font-weight: normal !important;
    text-decoration: underline;

    &:hover, &:focus {
      text-decoration: none;
    }
  }

  h2 {
    font-family: ${props => props.theme.font.family.special};
    color: ${props => props.theme.colors.navy};
    margin: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  h3 {
    font-family: ${props => props.theme.font.family.bold};
      font-weight: bold;
    color: ${props => props.theme.colors.navy};
    margin: 1rem 0;
    width: 100%;
    text-align: left;
  }

  a:not(.btn) {
    color: ${props => props.theme.colors.navy};
    font-weight: bold;
  }

  .imageWrapper {
    max-width: 800px;
    margin: 2rem auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .gatsby-image-wrapper {
      max-width: 400px;
    }

    &.smallImages {
      .gatsby-image-wrapper {
        max-width: 40px;
        margin: 0 10px;
      }
    }
  }

  
`

const ImgBottom = styled.div`
  position: absolute;
  width: 100%;
  bottom: -1px;
  left: 0;
  z-index: 2;
`

const VideoWrapper = styled.div`
  position: relative;
  z-index: 2;
  max-width: 922px;
  margin: 3rem auto;
`
const Query = () => (
    <StaticQuery
        query={graphql`
            query {
                ForegroundBottom: file(relativePath: { eq: "foreground-bottom.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                HeaderImageXs: file(relativePath: { eq: "AccessPage/bg-access-header-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
                HeaderImageSm: file(relativePath: { eq: "AccessPage/bg-access-header-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                HeaderImageXl: file(relativePath: { eq: "AccessPage/bg-access-header-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                videoPlaceholder1: file(relativePath: { eq: "AccessPage/access-video-1275.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1200, quality: 100)
                    }
                }
                
                videoPlaceholder2: file(relativePath: { eq: "making-frozen-the-musical-thumb.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1200, quality: 100)
                    }
                }
                
                galapro: file(relativePath: { eq: "AccessPage/1.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 500, quality: 100)
                    }
                }
                cc: file(relativePath: { eq: "AccessPage/2.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 40, quality: 100)
                    }
                }
                ad: file(relativePath: { eq: "AccessPage/3.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 40, quality: 100)
                    }
                }
                
            }
		`}
        render={data => (
            <IndexPage data={data}/>
        )}
    />
)

class IndexPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
            // src: "https://cdnapisec.kaltura.com/p/1068292/sp/106829200/embedIframeJs/uiconf_id/40058201/partner_id/1068292?iframeembed=true&playerId=kaltura_player&entry_id=1_54agprxu&flashvars&#91;localizationCode&#93;=en&&wid=1_btrvbqh7"
        }
    }
    
    toggleModal = (e, title, src) => {
        e.preventDefault();
        
        this.setState({
            src: src,
            title: title,
            modalOpen: !this.state.modalOpen
        });
    }

    onClick = () => {
        scrollToElement('#bottomOfIframe', {
            duration: 300
        });
    }

    render() {

        return (
            <Layout slug="access" title="Access Performances" pageName="access">
                <AccessHeader>
                    <GatsbyImage image={this.props.data.HeaderImageXs.childImageSharp.gatsbyImageData}
                                 alt=""
                                 title=""
                                 className="img-fluid d-block d-sm-none"
                    />
                    <GatsbyImage image={this.props.data.HeaderImageSm.childImageSharp.gatsbyImageData}
                                 alt=""
                                 title=""
                                 className="img-fluid d-none d-sm-block d-md-none"
                    />
                    <GatsbyImage image={this.props.data.HeaderImageXl.childImageSharp.gatsbyImageData}
                                 alt=""
                                 title=""
                                 className="img-fluid d-none d-md-block"
                    />

                    <ImgBottom>
                        <GatsbyImage image={this.props.data.ForegroundBottom.childImageSharp.gatsbyImageData} alt=""
                                     title=""
                                     className="img-fluid"/>
                    </ImgBottom>
                </AccessHeader>

                <HeadingSpecial headingTag="h1" className="pt-4 pt-sm-5"
                                title="Access & Relaxed Performances"/>


                <RelaxedPerformancesContent>
                    <Container fluid={true}>
                        <Container>
                            <p className="text-center color--navy mt-4 narrow">Frozen offers a range of Access
                                performances throughout the year. Dates and further information can be found below,
                                including how to book.</p>
                            <p className="text-center color--navy mt-4 narrow">LW Theatres provides an Access Scheme to which you can register. This will help you to
                                book performances, companion tickets, get information on future performances and more.
                            </p>

                            <p className="text-center color--navy narrow">
                                <a className="simple-link" href="https://lwtheatres.co.uk/accessibility/#registration-form">Register for LW Theatres Access Scheme</a>
                            </p>

                            <p className="text-center color--navy narrow">You can also book by phone or email.</p>

                            <div className="btnWrapper">
                                <a href="mailto:access@lwtheatres.co.uk"
                                   className="btn btn--new d-flex mx-auto mt-3"><span>access@lwtheatres.co.uk</span></a>
                                <a href="tel:02039252998"
                                   className="btn btn--new d-flex mx-auto mt-3"><span>Call: 020 3925 2998</span></a>
                            </div>
                        </Container>

                            <PaginatorLine/>

                        
                        <Container>
                            <h3 className="my-4">Audio Described Performances</h3>

                            <p className="color--navy text-left">Audio description in theatre is a live verbal commentary on the visual elements of a production, delivered as a performance unfolds, through a discreet headset.</p>

                            <div className="narrow">
                                <div className="btnWrapper">
                                    {/* <p className="mb-0 flex-grow-1 text-center font-weight-bold color--navy pr-2">Sunday 30 July 2023, 5:30pm</p> */}
                                    <p className="mb-0 flex-grow-1 text-center font-weight-bold color--navy pr-2">Friday 19th April 2024, 7:00pm </p>
                                </div>
                            </div>
                            <div className="btnWrapper">
                            <a href="https://ticketing.lwtheatres.co.uk/event/234/performance/48261"
                                          className="btn btn--new d-flex mx-auto mt-3 text-uppercase"><span>BOOK NOW</span></a>
                                {/* 
                                 */}
                                <a href="tel:02039252998"
                                   className="btn btn--new d-flex mx-auto mt-3"><span>Call: 020 3925 2998</span></a>
                            </div>
                        </Container>
                        <PaginatorLine className="mb-4"/>
                        <Container>
                            <h3 className="my-4">Captioned Performances</h3>
                            <p className="color--navy text-left">Captioning is a way of converting the spoken word into visible text that provides deaf, deafened and hard of hearing people with access to live performance.</p>
                            <p className="color--navy text-left">Captioned performance date to be announced. Live captioning via GalaPro is now available for all performances of Frozen the Musical. Click&nbsp;<a href="https://lwtheatres.co.uk/gala-pro/" target="_blank" rel="noopener noreferrer">here</a>&nbsp;to find out more information.</p>
                            {/* <div className="narrow">
                                <div className="btnWrapper">
                                    <p className="mb-0 flex-grow-1 text-center font-weight-bold color--navy pr-2">To be announced</p>
                                    
                                </div>
                            </div> */}
                            {/* <div className="btnWrapper">
                                <a href="mailto:access@lwtheatres.co.uk"
                                   className="btn btn--new d-flex mx-auto mt-3"><span>access@lwtheatres.co.uk</span></a>
                                <a href="tel:02039252998"
                                   className="btn btn--new d-flex mx-auto mt-3"><span>Call: 020 3925 2998</span></a>
                            </div> */}
                        </Container>
                        <PaginatorLine className="mb-4"/>
                        <Container>

                            <h3 className="my-4">Signed Performances</h3>

                            <p className="color--navy text-left">Throughout the performance, a qualified theatre sign interpreter stands on the left of the stage and uses sign language to relay music and words to deaf and hearing impaired patrons, all of whom are seated within easy viewing distance of both the stage and interpreter.</p>

                            <div className="narrow">
                                <div className="btnWrapper">
                                    {/* <p className="mb-0 flex-grow-1 text-center font-weight-bold color--navy pr-2">To be announced</p> */}
                                    <p className="mb-0 flex-grow-1 text-center font-weight-bold color--navy pr-2">Sunday 3rd March 2024, 1:00pm</p>
                                </div>
                            </div>
                            <div className="btnWrapper">
                                <a href="mailto:access@lwtheatres.co.uk"
                                className="btn btn--new d-flex mx-auto mt-3"><span>access@lwtheatres.co.uk</span></a>
                                <a href="tel:02039252998"
                                className="btn btn--new d-flex mx-auto mt-3"><span>Call: 020 3925 2998</span></a>
                            </div>
                            </Container>
                            <PaginatorLine className="mb-4"/>
                            <Container>
                            <h3 className="my-4">Relaxed Performances</h3>

                            <p className="color--navy text-left">Relaxed Performances are designed to provide an
                                opportunity for people who are autistic,
                                neurodiverse, or would benefit from reduced sensory input and might prefer a more
                                relaxed environment. Small adjustments are made to the sound, lighting and special
                                effects. There are extra trained staff on hand, and dedicated quiet areas are available
                                throughout the theatre should guests wish to leave their seats.</p>

                            <div className="narrow">
                                <div className="btnWrapper">
                                    <p className="mb-0 flex-grow-1 text-left font-weight-bold color--navy pr-2">Sunday 28 April 2024, 1:00pm</p>
                                    <a href="https://ticketing.lwtheatres.co.uk/event/234/performance/48231?PROMO=FRZRLXPERF"
                                          className="btn btn--new d-flex mx-auto mt-3 text-uppercase"><span>BOOK NOW</span></a>
                                </div>
                            </div>

                            <p className="color--navy"><a className="simple-link" href="/pdf/Frozen-Access-Journey.pdf" download={true}>
                                View our visual story here ahead of your visit to Frozen’s Relaxed Performance</a></p>

                            <p className="color--navy"><a className="simple-link" target="_blank" href="https://s3-eu-west-1.amazonaws.com/lwt-live/wp-content/uploads/2023/01/19171741/Frozen_DEC22_Sensory_Synopsis_34932SA.pdf">View our sensory synopsis ahead of your visit here</a> </p>

                            <VideoWrapper>
                                <VideoButton
                                    onClick={(e) => this.toggleModal(e, "Relaxed Performances at Disney's Frozen","https://secure.disney.com/embed/5ee9d9672c629ebd6b4f2a72?domain=www.disney.co.uk")}
                                    aria-label="Open video">
                                    <GatsbyImage
                                        image={this.props.data.videoPlaceholder1.childImageSharp.gatsbyImageData}
                                        alt="Relaxed Performances at Disney's Frozen"
                                        title="Relaxed Performances at Disney's Frozen"
                                        className="img-fluid d-block"
                                    />
                                </VideoButton>
                            </VideoWrapper>
                        </Container>


                            <PaginatorLine className="mb-4"/>

                       



                      

                        <Container>
                            <Row className="align-items-center mt-4 pt-4">
                                <Col xs={12} md={8} className="order-2 order-md-1">
                                    <p className="color--navy">GalaPro is now available at all performances of Frozen the Musical – a revolutionary app for accessibility, which enhances your experience at live performances. You can use the app to enjoy the show with subtitles, audio description and closed captioning. All services are provided in real-time.</p>
                                    <p className="color--navy">Click <a href="https://lwtheatres.co.uk/gala-pro/" target="_blank">here</a> to find out more.</p>
                                    <div className="imageWrapper smallImages">
                                        <GatsbyImage
                                            image={this.props.data.cc.childImageSharp.gatsbyImageData}
                                            alt="GalaPro"
                                            title="GalaPro"
                                            className="img-fluid d-block"
                                        /><GatsbyImage
                                            image={this.props.data.ad.childImageSharp.gatsbyImageData}
                                            alt="GalaPro"
                                            title="GalaPro"
                                            className="img-fluid d-block"
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} md={4} className="order-1 order-md-2">

                                    <GatsbyImage
                                        image={this.props.data.galapro.childImageSharp.gatsbyImageData}
                                        alt="GalaPro"
                                        title="GalaPro"
                                        className="img-fluid d-block"
                                    />
                                </Col>
                            </Row>
                        </Container>

                            <HeadingSpecial headingTag="h2" className="pt-4 pt-sm-5 pb-5"
                                            title="Frequently Asked Questions"/>

                        <Container>
                            <p className="text-left font-weight-bold color--navy">Are there access facilities at the theatre?</p>

                            <p className="text-left color--navy">Please visit the theatre's access page for full details about the facilities available:<br/>
                                <a className="simple-link" href="https://lwtheatres.co.uk/theatres/theatre-royal-drury-lane/accessibility/">Theatre Royal Drury Lane, London</a></p>
                            <p className="text-left font-weight-bold color--navy">Is there a minimum age to attend the show?</p>

                            <p className="text-left color--navy">FROZEN is recommended for a general audience aged 6 and up. Children under the age of 4 (including babes in arms) will not be admitted into the theatre. All persons aged 16 or under must be accompanied by an adult and may not sit on their own within the auditorium. All persons entering the theatre, regardless of age, must have a ticket.</p>
                            <p className="text-left font-weight-bold color--navy">Does the show feature any special effects?</p>

                            <p className="text-left color--navy">Please be advised that smoke, haze and strobe lights are used during the performance.</p>
                            <p className="text-left font-weight-bold color--navy">How long is the show?</p>

                            <p className="text-left color--navy">The performance lasts approximately 2 hours and 15 minutes including a one 20-minute interval.</p>
                            <p className="text-left font-weight-bold color--navy">Is the photography allowed in the theatre?</p>

                            <p className="text-left color--navy">No filming or photography is allowed at any time during the performance.</p>
                            <p className="text-left font-weight-bold color--navy">What steps will be taken to ensure the theatre is safe to visit when you open?</p>

                            <p className="text-left color--navy">You can read LW Theatre’s Audience Guide for up to date information on wellbeing procedures <a className="simple-link" href="https://lwtheatres.co.uk/lw-theatres-audience-guide/">here</a>.</p>
                            <p className="text-left font-weight-bold color--navy">How can wheelchair users see the show?</p>

                            <p className="text-left color--navy">The theatre offers non-transferable wheelchair spaces on all levels except for the Balcony. There is lift access to all levels of the theatre. To book and register with the theatre’s Access Scheme, please visit the <a className="simple-link" href="https://lwtheatres.co.uk/theatres/theatre-royal-drury-lane/accessibility/">Theatre Royal Drury Lane website</a>.</p>
                            <p className="text-left font-weight-bold color--navy">Is there a lift?</p>

                            <p className="text-left color--navy">A passenger lift is available to all 4 levels with non-transferable wheelchair spaces available on all levels except for the Balcony.<br/>
                                For further information, please visit the <a className="simple-link" href="https://lwtheatres.co.uk/theatres/theatre-royal-drury-lane/accessibility/">Theatre Royal Drury Lane website</a></p>
                            <p className="text-left font-weight-bold color--navy">I have a question about access that has not been answered here. Whom can I contact?</p>

                            <p className="text-left color--navy">If you have further questions, please email <a className="simple-link" href="mailto:access@lwtheatres.co.uk">access@lwtheatres.co.uk</a> or call <a className="simple-link" href="tel:02039252998">020 3925 2998</a>.</p>
                            <p className="text-left font-weight-bold color--navy">Do you have companion/carer tickets?</p>

                            <p className="text-left color--navy">Companion tickets are available for those who register to LW Theatre’s Access Scheme. For further information, please visit the <a className="simple-link" href="https://lwtheatres.co.uk/frozen-online-access-guide/">Theatre Royal Drury Lane website</a>.</p>

                        </Container>
                    </Container>
                </RelaxedPerformancesContent>

                <Map/>

                <VideoModal toggleModal={this.toggleModal} title={this.state.title} src={this.state.src} modalOpen={this.state.modalOpen}/>

            </Layout>
        )
    }
}

export default Query